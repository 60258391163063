import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { View } from 'react-native';
import { Avatar, Card, DataTable, Headline } from 'react-native-paper';
import moment from 'moment';
import DataTableRow from 'components/DataTableRow';
import { SCHEDULED_WORKOUT_FRAGMENT } from 'shared/graphql/fragments';

const GET_LIVE = gql`
  query GetLiveWorkouts {
    liveWorkouts {
      id
      token
      title
      liveCount
      viewerCount
      track {
        id
        name
        imageUrl
      }
    }
    scheduledWorkouts {
      ...ScheduledWorkoutFragment
      workout {
        token
        title
        track {
          name
          imageUrl
        }
      }
    }
  }
  ${SCHEDULED_WORKOUT_FRAGMENT}
`;

interface Props {}

const LiveWorkouts: React.FC<Props> = () => {
  const { data } = useQuery(GET_LIVE, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 30000,
  });
  const { currentUser } = useCurrentUser();

  const workouts = data ? data.liveWorkouts : [];
  const scheduledWorkouts = data ? data.scheduledWorkouts : [];

  return (
    <View>
      <Headline>Live Workouts</Headline>
      <Card>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title style={{ flex: 0.2 }}> </DataTable.Title>
            <DataTable.Title>Name</DataTable.Title>
            <DataTable.Title
              style={{ flex: currentUser.superadmin ? 0.4 : 0.8 }}
            >
              Athletes
            </DataTable.Title>
            {currentUser.superadmin && (
              <DataTable.Title style={{ flex: 0.4 }}>Viewers</DataTable.Title>
            )}
          </DataTable.Header>

          {workouts.map((workout: any) => {
            return (
              <DataTableRow
                key={workout.id}
                onPress={() => {
                  window.location.href = `/live/${workout.token}`;
                }}
              >
                <DataTable.Cell style={{ flex: 0.2 }}>
                  <Avatar.Image
                    size={30}
                    source={{ uri: workout.track.imageUrl }}
                  />
                </DataTable.Cell>
                <DataTable.Cell>
                  {workout.track.name} - {workout.title}
                </DataTable.Cell>
                <DataTable.Cell
                  style={{ flex: currentUser.superadmin ? 0.4 : 0.8 }}
                >
                  {workout.liveCount}
                </DataTable.Cell>
                {currentUser.superadmin && (
                  <DataTable.Cell style={{ flex: 0.4 }}>
                    {workout.viewerCount}
                  </DataTable.Cell>
                )}
              </DataTableRow>
            );
          })}
        </DataTable>
      </Card>

      <Headline style={{ marginTop: 40 }}>Scheduled Workouts</Headline>
      <Card>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title style={{ flex: 0.2 }}> </DataTable.Title>
            <DataTable.Title>Name</DataTable.Title>
            <DataTable.Title style={{ flex: 0.4 }}>Athletes</DataTable.Title>
            <DataTable.Title style={{ flex: 0.4 }}>Date</DataTable.Title>
          </DataTable.Header>

          {scheduledWorkouts.map((scheduledWorkout: any) => {
            return (
              <DataTableRow
                key={scheduledWorkout.id}
                onPress={() => {
                  window.location.href = `/live/${scheduledWorkout.workout.token}`;
                }}
              >
                <DataTable.Cell style={{ flex: 0.2 }}>
                  <Avatar.Image
                    size={30}
                    source={{ uri: scheduledWorkout.workout.track.imageUrl }}
                  />
                </DataTable.Cell>
                <DataTable.Cell>
                  {scheduledWorkout.workout.track.name} -{' '}
                  {scheduledWorkout.workout.title} - {scheduledWorkout.title}
                </DataTable.Cell>
                <DataTable.Cell style={{ flex: 0.4 }}>
                  {scheduledWorkout.numAthletes}
                </DataTable.Cell>
                <DataTable.Cell style={{ flex: 0.4 }}>
                  {moment(scheduledWorkout.scheduledAt).format(
                    'ddd, MMM D, h:mm A'
                  )}
                </DataTable.Cell>
              </DataTableRow>
            );
          })}
        </DataTable>
      </Card>
    </View>
  );
};

export default LiveWorkouts;
