import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormControl, MenuItem, Select, Tooltip } from '@material-ui/core';
import DataTableRow from 'components/DataTableRow';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import { startCase } from 'lodash';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import {
  Caption,
  Chip,
  DataTable,
  IconButton,
  Searchbar,
  Text,
} from 'react-native-paper';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useHistory, useLocation } from 'react-router-dom';
import CircularProgress from 'shared/components/CircularProgress';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import useDebounceState from 'shared/hooks/useDebounceState';
import { colors, theme } from 'shared/styles';
import {
  AdminUsersAction,
  ErgType,
  MembershipStatus,
  TrackMembership,
  TrackMode,
  TrackRole,
  User,
  UserDisplay,
} from 'shared/types';
import {
  calculatePace,
  formatKmDistance,
  formatTime,
  formatTimeWithHours,
} from 'shared/utils';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import styled from 'utils/styled';

const USER_FRAGMENT = gql`
  fragment MembershipUserFragment on User {
    id
    name(display: $userDisplay)
    email
    appSubscription {
      statusMessage
    }
    concept2Info {
      id
      username
    }
    workoutResultsCount
    trackMembership(trackId: $trackId) {
      statusMessage
    }
    adminActions(trackId: $trackId)
    role: trackRole(trackId: $trackId)
    lastPlanCycle(trackId: $trackId) {
      progress {
        progress
        distance
        time
      }
    }
  }
`;

const GET_USERS = gql`
  query GetUsers(
    $offset: Int
    $guest: Boolean
    $search: String
    $trackId: ID!
    $statuses: [String]
    $userDisplay: Int
    $userSort: String
  ) {
    track(id: $trackId) {
      id
      trackMode
      membershipStatuses
      admins {
        id
        name
      }
      userTrackRequests {
        id
        user {
          ...MembershipUserFragment
        }
      }
      searchUser(username: $search) {
        ...MembershipUserFragment
      }
    }
    usersCount(trackId: $trackId, membershipStatuses: $statuses)
    users(
      offset: $offset
      guest: $guest
      sort: $userSort
      search: $search
      trackId: $trackId
      membershipStatuses: $statuses
    ) {
      ...MembershipUserFragment
    }
  }
  ${USER_FRAGMENT}
`;

const TRACK_MEMBERSHIP_UPDATE_MUTATION = gql`
  mutation TrackMembershipUpdate(
    $trackId: ID!
    $userId: ID!
    $status: String!
    $userDisplay: Int
  ) {
    trackMembershipUpdate(trackId: $trackId, userId: $userId, status: $status) {
      ...MembershipUserFragment
    }
  }
  ${USER_FRAGMENT}
`;

const TRACK_REQUEST_APPROVAL_MUTATION = gql`
  mutation TrackRequestApproval(
    $trackId: ID!
    $userId: ID!
    $approve: Boolean!
    $userDisplay: Int
  ) {
    trackRequestApproval(
      trackId: $trackId
      userId: $userId
      approve: $approve
    ) {
      ...MembershipUserFragment
    }
  }
  ${USER_FRAGMENT}
`;

const TRACK_ROLE_UPDATE_MUTATION = gql`
  mutation TrackRoleUpdate($trackId: ID!, $userId: ID!, $role: String!) {
    trackRoleUpdate(trackId: $trackId, userId: $userId, role: $role) {
      id
      role: trackRole(trackId: $trackId)
    }
  }
`;

interface UserTrackRequest {
  user: UserData;
}

interface TrackData {
  id: string;
  membershipStatuses: string[];
  userTrackRequests: UserTrackRequest[];
  searchUser?: UserData;
  trackMode: TrackMode;
}

interface PlanProgress {
  progress: number;
  distance: number;
  time: number;
}

interface PlanCycle {
  progress: PlanProgress;
}

interface UserData extends User {
  trackMembership?: TrackMembership;
  adminActions: AdminUsersAction[];
  lastPlanCycle: PlanCycle;
  role?: TrackRole;
}

interface QueryData {
  users: UserData[];
  usersCount: number;
  track: TrackData;
}

interface Props {
  trackId: string;
  isPlan: boolean;
  onUsersCount(count: number): void;
  userDisplay: UserDisplay;
}

const FilterContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const ToggleMembershipButton = ({
  user,
  track,
  userDisplay,
  refetch,
}: {
  user: UserData;
  userDisplay: UserDisplay;
  track: TrackData;
  refetch(): void;
}) => {
  const confirmWith = useConfirmModal();
  const [trackMembershipUpdate, { loading: loadingMembership }] = useMutation(
    TRACK_MEMBERSHIP_UPDATE_MUTATION
  );
  const [trackRequestApprovalMutation, { loading: loadingApproval }] =
    useMutation(TRACK_REQUEST_APPROVAL_MUTATION);

  const [trackRoleUpdate, { loading: savingRole }] = useMutation(
    TRACK_ROLE_UPDATE_MUTATION
  );

  return (
    <>
      {user.adminActions.map((action) => {
        if (action === AdminUsersAction.Add) {
          return (
            <Tooltip title={'Grant Access'} key={action}>
              <span>
                <IconButton
                  disabled={loadingMembership}
                  icon={'plus-circle-outline'}
                  size={20}
                  onPress={() => {
                    confirmWith({
                      message: `Are you sure you want to grant access to '${user.name}'?${
                        track.trackMode === TrackMode.Tiered
                          ? '\n\nAdding new users might change your tier plan in case the max number of users in your current plan is exceeded.'
                          : ''
                      }`,
                      confirmLabel: 'Grant Access',
                      onConfirm: () => {
                        trackMembershipUpdate({
                          variables: {
                            userId: user.id,
                            trackId: track.id,
                            status: MembershipStatus.AccessGranted,
                            userDisplay,
                          },
                        });
                      },
                    });
                  }}
                />
              </span>
            </Tooltip>
          );
        } else if (action === AdminUsersAction.Remove) {
          return (
            <Tooltip title={'Remove Access'} key={action}>
              <span>
                <IconButton
                  disabled={loadingMembership}
                  icon={'trash-can-outline'}
                  size={20}
                  onPress={() => {
                    confirmWith({
                      message: `Are you sure you want to remove access from '${user.name}'?`,
                      confirmLabel: 'Remove Access',
                      onConfirm: () => {
                        trackMembershipUpdate({
                          variables: {
                            userId: user.id,
                            trackId: track.id,
                            status: MembershipStatus.AccessRemoved,
                            userDisplay,
                          },
                        });
                      },
                    });
                  }}
                />
              </span>
            </Tooltip>
          );
        } else if (action === AdminUsersAction.Approve) {
          return (
            <Tooltip title={'Approve Access'} key={action}>
              <span>
                <IconButton
                  disabled={loadingApproval}
                  icon={'check-circle-outline'}
                  color="green"
                  size={20}
                  onPress={() => {
                    confirmWith({
                      message: `Are you sure you want to grant access to '${user.name}'?`,
                      confirmLabel: 'Grant Access',
                      onConfirm: () => {
                        trackRequestApprovalMutation({
                          variables: {
                            trackId: track.id,
                            userId: user.id,
                            approve: true,
                            userDisplay,
                          },
                        }).then(refetch);
                      },
                    });
                  }}
                />
              </span>
            </Tooltip>
          );
        } else if (action === AdminUsersAction.Reject) {
          return (
            <Tooltip title={'Reject Access'} key={action}>
              <span>
                <IconButton
                  disabled={loadingApproval}
                  icon={'close-circle-outline'}
                  color="red"
                  size={20}
                  onPress={() => {
                    confirmWith({
                      message: `Are you sure you want to reject access to '${user.name}'?`,
                      confirmLabel: 'Reject Access',
                      onConfirm: () => {
                        trackRequestApprovalMutation({
                          variables: {
                            trackId: track.id,
                            userId: user.id,
                            approve: false,
                            userDisplay,
                          },
                        }).then(refetch);
                      },
                    });
                  }}
                />
              </span>
            </Tooltip>
          );
        } else if (action === AdminUsersAction.ToggleAdmin) {
          const label =
            user.role === TrackRole.Admin
              ? 'Remove Admin Access'
              : 'Grant Admin Access';
          return (
            <Tooltip title={label} key={action}>
              <span>
                <IconButton
                  disabled={savingRole}
                  icon={
                    user.role === TrackRole.Member
                      ? 'shield-account'
                      : 'shield-off'
                  }
                  size={20}
                  onPress={() => {
                    confirmWith({
                      message: `Are you sure you want to ${
                        user.role === TrackRole.Admin
                          ? 'remove admin access to'
                          : 'grant admin access to'
                      } '${user.name}'?`,
                      confirmLabel: label,
                      onConfirm: () => {
                        trackRoleUpdate({
                          variables: {
                            userId: user.id,
                            trackId: track.id,
                            role:
                              user.role === TrackRole.Admin
                                ? TrackRole.Member
                                : TrackRole.Admin,
                          },
                        }).then(refetch);
                      },
                    });
                  }}
                />
              </span>
            </Tooltip>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

const size = 30;
const ProgressDisplay = ({
  progress,
  ergType,
}: {
  progress: PlanProgress;
  ergType: ErgType;
}) => {
  const { time, distance, progress: cycleProgress } = progress;
  const pace = calculatePace(time / 100, distance / 10, ergType);

  if (distance === 0) return <Text>-</Text>;

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <CircularProgress
        bgColor={colors.primaryColor}
        color={colors.planColor}
        size={size}
        progress={cycleProgress}
      >
        <Text
          style={[
            {
              fontSize: size * 0.4,
              color: colors.white,
              fontFamily: theme.fonts.medium.fontFamily,
            },
          ]}
        >
          {cycleProgress}
          <Text
            style={[
              {
                fontSize: size * 0.3,
                color: colors.white,
                fontFamily: theme.fonts.medium.fontFamily,
              },
            ]}
          >
            %
          </Text>
        </Text>
      </CircularProgress>
      <Text style={{ color: theme.colors.placeholder, marginLeft: 4 }}>
        <MaterialCommunityIcon name="clock-outline" />
        {` ${formatTimeWithHours(time / 100)}  `}
        <MaterialCommunityIcon name="road-variant" />
        {` ${formatKmDistance(distance / 10)}  `}
        <MaterialCommunityIcon name="speedometer" />
        {` ${formatTime(pace, true)}`}
      </Text>
    </View>
  );
};

const UsersList: React.FC<Props> = ({
  trackId,
  onUsersCount,
  isPlan,
  userDisplay,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { currentUser } = useCurrentUser();
  const [pageParam, setPage] = useQueryParam('page', NumberParam);
  const page = pageParam || 1;

  const [guestParam, setGuest] = useQueryParam('guest', StringParam);
  const guest = guestParam === 'true';

  const [statusParam, setStatusParam] = useQueryParam('status', StringParam);
  const status = statusParam || 'active';

  const [search, setSearch, unbouncedSearch] = useDebounceState(
    new URLSearchParams(location.search).get('search') || '',
    (newValue) => {
      const searchParams = new URLSearchParams(location.search);
      if (newValue) {
        searchParams.set('search', newValue);
      } else {
        searchParams.delete('search');
      }
      history.replace({ search: searchParams.toString() });
    }
  );

  const { data, refetch } = useQuery<QueryData>(GET_USERS, {
    variables: {
      offset: (page - 1) * 10,
      guest,
      search: !!search ? search : null,
      trackId,
      statuses: status !== 'all' ? [status] : [],
      userDisplay,
      userSort:
        userDisplay === UserDisplay.LastName ? 'lastName.asc' : 'name.asc',
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      if (onUsersCount && response.usersCount) {
        onUsersCount(response.usersCount);
      }
    },
  });

  useEffect(() => {
    return () => onUsersCount && onUsersCount(0);
  }, [onUsersCount]);

  const users = data ? data.users : [];
  const track = data ? data.track : null;
  const requests = track ? track.userTrackRequests : [];
  const searchUsers = track && track.searchUser ? [track.searchUser] : [];
  const searchUserId = track && track.searchUser && track.searchUser.id;
  const hasMemberships = track ? track.membershipStatuses.length > 0 : false;
  return (
    <View>
      <FilterContainer>
        <Searchbar
          inputStyle={{ width: 300, fontSize: 15 }}
          style={{ marginRight: 10 }}
          placeholder={
            users.length === 1
              ? 'Search by C2 username'
              : 'Search by name, email, or C2 username'
          }
          onChangeText={setSearch}
          value={unbouncedSearch}
        />
        {hasMemberships && (
          <FormControl variant="outlined" margin="dense">
            <Select
              value={status}
              onChange={(e) => {
                if (page !== 1) {
                  setPage(1);
                }
                setStatusParam(String(e.target.value));
              }}
            >
              <MenuItem value="all">
                <em>All Status</em>
              </MenuItem>
              {track!.membershipStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {startCase(status)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {currentUser.superadmin && (
          <Chip
            icon="account"
            style={{ marginLeft: 10 }}
            selected={guest}
            onPress={() => setGuest(String(!guest))}
          >
            Guest
          </Chip>
        )}
        <Tooltip
          title="To add new users to your group, search for their exact Concept2
              Logbook username, Logbook ID or email. You can also send them a temporary link to join. Open the DETAILS tab to find the temporary link."
        >
          <span>
            <Caption style={{ marginLeft: 10 }}>Add new user?</Caption>
          </span>
        </Tooltip>
      </FilterContainer>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          {currentUser.superadmin && (
            <>
              <DataTable.Title>Email</DataTable.Title>
              <DataTable.Title>EZ Subscription</DataTable.Title>
            </>
          )}
          <DataTable.Title>Username</DataTable.Title>
          {hasMemberships && <DataTable.Title>Status</DataTable.Title>}
          {isPlan ? (
            <DataTable.Title>Progress</DataTable.Title>
          ) : (
            <DataTable.Title style={{ flex: 0.4 }}>Results</DataTable.Title>
          )}
          <DataTable.Title style={{ flex: 0.4 }} numeric>
            Actions
          </DataTable.Title>
        </DataTable.Header>

        {[...searchUsers, ...requests.map((r) => r.user), ...users].map(
          (user: any) => {
            return (
              <DataTableRow
                key={user.id}
                onPress={() => history.push(`/users/${user.id}`)}
              >
                <DataTable.Cell>{user.name}</DataTable.Cell>
                {currentUser.superadmin && (
                  <>
                    <DataTable.Cell>{user.email || '-'} </DataTable.Cell>
                    <DataTable.Cell>
                      {user.appSubscription.statusMessage}{' '}
                    </DataTable.Cell>
                  </>
                )}
                <DataTable.Cell>
                  {user.concept2Info ? (
                    <Tooltip title={`Logbook ID: ${user.concept2Info.id}`}>
                      <span>{user.concept2Info.username}</span>
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </DataTable.Cell>
                {hasMemberships && (
                  <DataTable.Cell>
                    {user.trackMembership
                      ? user.trackMembership.statusMessage
                      : user.id === searchUserId && 'Not a member'}
                  </DataTable.Cell>
                )}
                {isPlan ? (
                  <DataTable.Cell>
                    {user.lastPlanCycle ? (
                      <ProgressDisplay
                        progress={user.lastPlanCycle.progress}
                        ergType={ErgType.row}
                      />
                    ) : (
                      '-'
                    )}
                  </DataTable.Cell>
                ) : (
                  <DataTable.Cell style={{ flex: 0.4 }}>
                    {user.id === searchUserId ? '-' : user.workoutResultsCount}
                  </DataTable.Cell>
                )}
                <DataTable.Cell style={{ flex: 0.4 }} numeric>
                  {track && (
                    <ToggleMembershipButton
                      user={user}
                      track={track}
                      userDisplay={userDisplay}
                      refetch={refetch}
                    />
                  )}
                </DataTable.Cell>
              </DataTableRow>
            );
          }
        )}

        <DataTable.Pagination
          page={page}
          numberOfPages={0}
          onPageChange={setPage}
          label={`Page ${page}`}
        />
      </DataTable>
    </View>
  );
};

export default UsersList;
