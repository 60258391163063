import { theme } from 'shared/styles';
import { CurrentUser, SubscriptionStore } from 'shared/types';

export const muiThemeAttributes = {
  typography: {
    fontFamily: ['"Nunito"', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: theme.colors.primary,
    },
    secondary: {
      main: theme.colors.accent,
    },
  },
};

export const isBillingAvailable = (currentUser: CurrentUser) =>
  currentUser.appSubscription.store === SubscriptionStore.stripe ||
  currentUser.appSubscription.store === SubscriptionStore.manual ||
  !currentUser.appSubscription.active;

export function pm5Firmware(metadata?: { pm5Fw?: string }) {
  const firmwares = (metadata && metadata.pm5Fw) || '';

  return firmwares
    .split('|')
    .map((firmware: string) => {
      const match = firmware.match(/(\d+).\d+$/i);
      return match ? match[1] : '';
    })
    .join('|');
}
